import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  CreateDistrictUserGQL,
  CreateTeacherUserGQL,
  GetGroupCodeGQL,
  GetGroupCodeQuery,
  GroupCodeType,
  IsUsernameAvailableGQL,
  UserCreationType,
  UserEduProfileDto,
} from 'generated/graphql';
import { isUndefined } from 'lodash';
import { Observable, of } from 'rxjs';
import { debounceTime, delay, map, take } from 'rxjs/operators';
import { TeacherSignupDto } from '../../../generated/graphql';

@Injectable()
export class TeacherSignupService {
  type: GroupCodeType;
  groupCodeData: GetGroupCodeQuery['getGroupCode'];
  constructor(
    private getGroupCodeService: GetGroupCodeGQL,
    private isUsernameAvailableGQL: IsUsernameAvailableGQL,
    private createTeacherUserGQL: CreateTeacherUserGQL,
    private createDistrictUserGQL: CreateDistrictUserGQL
  ) {}

  setGroupCodeType(type: GroupCodeType) {
    this.type = type;
  }

  groupCodeValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkGroupCodeAccess(control.value).pipe(
        map((res) => {
          switch (res) {
            case 'expirationDate': {
              return { expirationDate: true };
            }
            case 'numLicenses': {
              return { numLicenses: true };
            }
            case 'noCode': {
              return { invalidCode: true };
            }
            case 'none': {
              return null;
            }
            default: {
              return { unknown: true };
            }
          }
          // return res ? { codeIsInvalid: true } : null
        })
      );
    };
  }

  emailAvailabilityValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkEmailAvailability(control.value).pipe(
        map((res) => {
          return res ? null : { emailExists: true };
        })
      );
    };
  }

  checkGroupCodeAccess(code: string): Observable<string> {
    if (code.length != 8) {
      return of('invalidLength');
    }
    return this.getGroupCodeService.fetch({ code, type: this.type }, { errorPolicy: 'all' }).pipe(
      debounceTime(500),
      delay(100),
      take(1),
      map((result) => {
        if (result.errors) {
          return 'noCode';
        }
        this.groupCodeData = result.data.getGroupCode;
        if (this.groupCodeData && this.groupCodeData.isActive) {
          if (!!this.groupCodeData.expirationDate && this.groupCodeData.expirationDate >= new Date()) return 'expirationDate';
          if (!isUndefined(this.groupCodeData.numLicenses) && typeof this.groupCodeData.numLicenses === 'number') {
            if (!isUndefined(this.groupCodeData.numLicenses) && this.groupCodeData.users.length >= this.groupCodeData?.numLicenses) return 'numLicenses';
          }
          return 'none';
        }
        return 'noCode';
      })
    );
  }

  checkEmailAvailability(email: string): Observable<boolean> {
    return this.isUsernameAvailableGQL.fetch({ username: email }, { fetchPolicy: 'no-cache' }).pipe(
      debounceTime(500),
      take(1),
      map(({ data }) => {
        return data.isUsernameAvailable;
      })
    );
  }

  matchEmails(control: AbstractControl): ValidationErrors | null {
    const email = control.get('email')?.value;
    const emailVerify = control.get('emailVerify')?.value;
    const emailVerifyControl = control.get('emailVerify');
    const emailMatch = email === emailVerify ? null : { emailMismatch: true };
    if (emailVerifyControl && email !== emailVerify) {
      emailVerifyControl.setErrors({ emailMismatch: true });
    }
    return emailMatch;
  }

  checkEmailDomain(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email: string = control.get('email')?.value;
      let domains = this.groupCodeData?.group.domains;

      // If there is nothing to check then dont return an error
      if (!domains || domains.length == 0) {
        return null;
      }

      // Make sure all the domains in the list are lowercase.
      domains = domains.filter((domain) => domain.toLowerCase());

      // Split the email and see if the domain matches.
      const email_domain = email.split('@')[1] || '';
      return domains.includes(email_domain.toLowerCase()) ? null : { emailBadDomain: true };
    };
  }

  postTeacherSignup(teacherSignupData: TeacherSignupDto, userEduProfileDto: UserEduProfileDto | undefined, creationType: UserCreationType) {
    return this.createTeacherUserGQL.mutate({ teacherSignupData, userEduProfileDto, creationType }, { fetchPolicy: 'no-cache' }).pipe(
      map(({ data }) => {
        return data?.createTeacherUser;
      })
    );
  }

  postDistrictSignup(districtSignupData: TeacherSignupDto, creationType: UserCreationType) {
    return this.createDistrictUserGQL.mutate({ districtSignupData, creationType }, { fetchPolicy: 'no-cache' }).pipe(
      map(({ data }) => {
        return data?.createDistrictUser;
      })
    );
  }
}
